.bioContainer {
    padding: var(--paddingMedium);
}

.engagementListItem{
    list-style: none;
    padding: var(--paddingXSmall);
}

.engagementIcon{
    color: var(--pallettePrimary);
}


.linkTitle{
    font-size: 1.1rem;
}

@media only screen and (max-width: 768px) {
    .ul{
        padding-left: var(--paddingSmall);
    }
}