@import "animate.css";

.rightCard,
.leftCard {
	width: 40%;
	max-width: 400px;
	padding: var(--paddingLarge);
	display: inline-block;
	padding-right: 7%;
}

.rightCard {
	/* margin-left: 50%; */
	padding-left: 7%;
	/* display: inline-block; */
}

.titleLink {
	font-size: 1.6rem;
	font-weight: 500;
	color: var(--pallettePrimary);
	text-decoration: none;
}

.titleLink:hover {
	font-weight: 700;
}

.sectionContainer,
.sectionContainerReverse {
	--imageWidth: 300px;
	margin: var(--marginMedium);
	display: flex;
	justify-content: center;
	opacity: 0;
}

.sectionContainer :global,
.sectionContainerReverse :global {
	animation: fadeIn 1.5s forwards;
	animation-delay: 0.3s;
}

.sectionContainerReverse {
	flex-direction: row-reverse;
}

.imgLeft,
.imgRight {
	width: 30vw;
	max-width: 400px;
	object-fit: cover;

	z-index: 2;
	position: relative;
	margin-left: -5%;

	border-radius: 5px;
	box-shadow: 0 6px 10px rgba(0, 0, 0, 0.5);

	opacity: 0;
}

.imgLeft:global,
.imgRight:global {
	animation: fadeIn var(--animationDurationMedium) forwards;
	animation-delay: var(--animationDelayLong);
}

.imgLeft {
	margin-right: -5%;
}

@media only screen and (max-width: 768px) {
	.rightCard,
	.leftCard {
		width: 90%;
		padding: var(--paddingMedium);
		padding-bottom: var(--paddingLarge);
	}

	.sectionContainer,
	.sectionContainerReverse {
		margin: var(--marginSmall);
		margin-bottom: var(--marginMedium);
		flex-direction: column;
		align-items: center;
	}

	.imgLeft,
	.imgRight {
		margin: 0;
		width: 90%;
		margin-top: -7%;
	}
}
