@import "animate.css";

/* p {
	animation: pulse 1s;
	animation-delay: 1.3s;
}

main {
	animation: fadeIn 1s forwards;
	/* animation-delay: 1s; */
/* } */

:root {
	--palletteWhite: #f7f6f6;
	--palletteAccentLight: #c94473;
	--palletteAccentDark: #7f8692;
	--pallettePrimary: #4272b2;
	/* --pallettePrimaryDark: #183045; */
	--pallettePrimaryDark: #0c161e;

	--bgColor: #183045;

	--infoColor: #183045;
	--successColor: #499d6d;
	--warningColor: #c68d35;
	--errorColor: #f44336;

	--paddingXSmall: 5px;
	--paddingSmall: 10px;
	--paddingMedium: 25px;
	--paddingLarge: 50px;

	--marginXSmall: 5px;
	--marginSmall: 10px;
	--marginMedium: 25px;
	--marginLarge: 50px;

	--animationDelayShort: 0.1s;
	--animationDelayMedium: 0.3s;
	--animationDelayLong: 0.5s;

	--animationDurationShort: 0.5s;
	--animationDurationMedium: 1.5s;
	--animationDurationLong: 2.5s;
}

body {
	background-color: var(--bgColor);
	font-family: Gotham A, Gotham B, sans-serif;
}

button {
	padding: var(--paddingSmall) var(--paddingMedium);
	border: 2px solid;
	border-radius: 4px;
	border-color: var(--pallettePrimary);
	transition: color 0.2s, border-color 0.2s, box-shadow 0.2s, transform 0.2s;
}

button:hover:enabled {
	color: var(--palletteAccentLight);
	border-color: var(--palletteAccentLight);
	box-shadow: 0 0.5em 0.5em -0.4em var(--palletteAccentLight);
	/* Lift using transform */
	transform: translateY(-0.25em);
}

button:disabled {
	border-color: var(--palletteAccentDark);
}
