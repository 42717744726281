.neighbourhoodFilterInput {
	margin: var(--marginMedium);
	font-size: 1.2rem;
	padding: var(--paddingSmall);
	opacity: 0;
}

.neighbourhoodFilterInput:global {
	animation: fadeIn var(--animationDurationMedium) forwards;
	animation-delay: var(--animationDelayMedium);
}

.neighbourhoodListContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	padding: var(--paddingMedium);
}

.linkCard {
	color: black;
	border-radius: 12px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
	background-color: var(--palletteWhite);

	width: 20%;
	min-width: 250px;
	min-height: 50px;

	padding: 10px;
	margin: var(--marginSmall);

	text-decoration: none;

	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	text-align: center;

	opacity: 0;
}

.linkCard :global {
	animation: fadeIn var(--animationDurationMedium) forwards;
	animation-delay: var(--animationDelayMedium);
}

.linkCard:hover {
	font-weight: 700;
	font-size: 1.2rem;
	background-color: var(--palletteAccentDark);
	/* text-decoration: underline; */
	color: white;

	/* flex-grow: 1; */
	/* color: white; */
}

@media only screen and (max-width: 768px) {
	.neighbourhoodFilterInput {
		/* position: relative; */
		margin: var(--marginSmall) auto;
		display: block;
		font-size: 1.2rem;
		padding: var(--paddingSmall);
		opacity: 0;
	}

	.neighbourhoodListContainer {
		justify-content: center;
		padding: var(--paddingSmall);
	}

	.linkCard {
		padding: 5px 10px;
		margin: var(--marginXSmall);
	}
}
