.notFoundDiv {
	display: flex;
	align-items: center;
	align-content: center;
	flex-direction: column;
}

.notFoundImage {
	max-width: 300px;
}

.notFoundCard {
	min-width: fit-content;
	width: 40%;
	display: flex;
	align-items: center;
	align-content: center;
	flex-direction: column;
	padding-bottom: var(--paddingMedium);
}

.cardTitle {
	/* display: inline-block; */
	width: fit-content;
}
