.footer {
	padding: 1vh 0;
	min-height: 5vh;
	margin: 0 auto;
	width: 100%;
	background-color: var(--pallettePrimary);
}

.footer ul {
	list-style-type: none;
	display: flex;
	gap: 4rem;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	text-transform: uppercase;
	padding-left: 0;
}

.footer a {
	font-size: 1.25rem;
	text-decoration: none;
	color: #ffffff;
}

.footer a:hover,
.footer a.active {
	color: var(--palletteAccentLight);
}

@media only screen and (max-width: 768px) {
	.footer ul {
		gap: 10vw;
	}

	.footer li {
		width: 40vw;
		text-align: center;
	}
}
