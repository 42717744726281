.yearGraphCard{
    width: 90%;
    margin: var(--marginMedium) auto;
    height: 60vh;
}

/* .yearGraphCard tspan:global:hover{
    background-color: aqua;
} */

.monthGraphCard{
    width: 90%;
    margin: var(--marginMedium) auto;
    height: 40vh;
}

@media only screen and (max-width: 768px) {
    .yearGraphCard{
        width: 95%;
        margin: var(--marginSmall) auto;
        height: 100vh;
    }
    .monthGraphCard{
        width: 95%;
        margin: var(--marginSmall) auto;
        height: 50vh;
    }
}